.App {
  text-align: center;
}
.nav{
  overflow: hidden;
  display: flex;
 justify-content: space-between;
  
}
.nav h1{
  font-family: 'Sigmar One', cursive;
  font-size: 50px;
  margin-top: 10px;
  color: #f1bb73;
 

}
.nav img{
  width: 165px;
  height: 90px;
}
.app__contact{
  border: none;
  background-color: #D9F8C4;
  text-decoration: none;
  height: 50px;
  width: 10%;
  border-radius: 6px;
  margin: 2%;
  font-family: 'Sigmar One', cursive;
  font-size: 24px;
  text-align: center;
  box-shadow: 2px 2px 2px #7F8487;
  color: #333333;
}
#terms{
  background-color: #CAF0F8;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
#right{
  margin-left: 140px;
}

#left{
  margin-right: 165px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.bun{
  width: 200px;
}
@media only screen and (max-width: 450px) {
  .bun{
    width: 100px;
  }
  #left{
    margin-right: 20px;
  }
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
#right{
  margin-left: 30px;
}

#left{
  margin-right: 60px;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
