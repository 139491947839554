
.contact__container{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 10px;
  background-color: #ffff;
  opacity: 0.7;
  padding: 7%;
}
.contact__container h1 {
  text-align: center;
  font-family: 'Sigmar One', cursive;

}
.contact strong{
  font-family: 'Sigmar One', cursive;
 
}
.contact__message textarea {
  border: none;
  width: 100%;
  background-color: #22222222;
  margin-top: 1%;
  margin-bottom: 1%;
  border-radius: 3px;
  height: 80px;
}
.contact input {
  border: none;
  width: 100%;
  background-color: #22222222;
  margin-top: 1%;
  margin-bottom: 1%;
  border-radius: 3px;
  height: 25px;
}
.contact__button button{
  border: none;
  background-color: #D9F8C4;
  height: 75px;
  font-family: 'Sigmar One', cursive;
  border-radius: 3px;
  padding-left: 3%;
  padding-right: 3%;
  font-size: 24px;


}

